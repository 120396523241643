<template>
  <div>
    <div v-if="tableData">
      <el-table
          :data="tableData"
          :show-header="false"
          style="width: 100%"
      >
        <el-table-column
            prop="text"
            label="项目"
            width="110"
        >
        </el-table-column>
        <el-table-column
            prop="value"
            label="内容"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {getBulk12Details} from '@/api/eChnenergyTask'

export default {
  name: 'Bulk12',
  data() {
    return {
      tableData: undefined
    }
  },
  mounted() {
    document.title = '煤炭招标-宁波海运'
    getBulk12Details({id: this.$route.params.id}).then(res => {
      console.log(res)
      this.tableData = [
        {text: '货盘编号', value: res[0].cargoPalletCode},
        {text: '推送时间', value: res[0].createTime},
        {text: '货主', value: res[0].shipperName},
        {text: '货量(吨)', value: res[0].quantity},
        {text: '装港-卸港', value: res[0].loadingPort + '-' + res[0].unLoadingPort},
        {text: '受载期', value: res[0].laycan},
        {text: '报价截止时间', value: res[0].validity},
      ]
    })
  },
  methods: {}
}
</script>

<style scoped>

</style>
